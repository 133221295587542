<template>
    <v-container>
        <v-form @submit.prevent="InsertUpdate()">
            <v-row>
                <v-col cols="12" sm="12" md="z" lg="3">
                    <v-text-field label="ชื่อเข้าระบบ"
                                  placeholder="ชื่อเข้าระบบ"
                                  outlined
                                  required
                                  :rules="[rules.required(UserStatus)]"
                                  ref="UserAccount"
                                  dense
                                  v-model="User.UserAccount"
                                  @blur="Blur(User.UserAccount)"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-text-field label="รหัสผ่าน"
                                  placeholder="รหัสผ่าน"
                                  outlined
                                  required
                                  dense
                                  v-model="User.UserPassword"
                                  type="password"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-select v-model="User.UserLevel"
                              :items="LevelUser"
                              label="ระดับ"
                              placeholder="เลือกระดับ"
                              dense
                              outlined
                              required></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-text-field label="ชื่อ"
                                  placeholder="ชื่อ  นามสกุล"
                                  outlined
                                  required
                                  dense
                                  v-model="User.UserName"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-text-field label="โทรศัพท์"
                                  placeholder="เบอร์โทรศัพท์"
                                  outlined
                                  dense
                                  v-model="User.UserTel"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="6">
                    <v-textarea label="หมายเหตุ"
                                placeholder="หมายเหตุ"
                                outlined
                                dense
                                v-model="User.UserRemark"></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="1">
                    <v-btn block type="submit">บันทึก</v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="1">
                    <v-btn block @click="$router.push('/user')">กลับ</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
    import Axios from "axios";

    export default {
        data() {
            return {
                User: {
                    UserId: "",
                    UserName: "",
                    UserTel: "",
                    UserRemark: "",
                    UserAccount: "",
                    UserPassword: "",
                    UserLevel: "2",
                    UserMoneyLimt: "0",
                    Org: []
                },
                LevelUser: [],
                UserStatus: true,
                UserAccount: "",
                rules: {
                    required: value => !!value || "เป็นสมาชิกแล้ว"
                },
                OrganizationId: "",
                Organization: []
            };
        },
        mounted() {
            this.$nextTick(() => {
                if (this.$store.state.User.UserLevel == "M") {
                    this.LevelUsers();
                    if (this.$route.query.UserId != null) {
                        this.User.UserId = this.$route.query.UserId;
                        this.SelectId(this.User.UserId);
                    }
                } else {
                    this.$router.push("/Member");
                }

            });
        },
        methods: {
            LevelUsers() {
                Axios.get("/Api/V1/Select/SelectLevel/User").then(res => {
                    if (res.status == 200) {
                        this.LevelUser = res.data;
                    }
                });
            },
            Blur(v) {
                if (!v) return;
                Axios.post("/Api/V1/Select/SelectCheckUser/User", { UserName: v }).then(
                    res => {
                        if (res.status == 200) {
                            if (res.data.UserStatus) {
                                if (this.UserAccount != v && this.$route.query.UserId != null) {
                                    this.UserStatus = false;
                                    this.User.UserAccount = "";
                                    this.$refs.UserAccount.focus();
                                } else if (this.$route.query.UserId == null) {
                                    this.UserStatus = false;
                                    this.User.UserAccount = "";
                                    this.$refs.UserAccount.focus();
                                } else {
                                    this.UserStatus = true;
                                }
                            } else {
                                this.UserStatus = true;
                            }
                        }
                    }
                );
            },
            SelectId(v) {
                Axios.get("/Api/V1/Select/SelectId/User?UserId=" + v).then(res => {
                    if (res.status == 200) {
                        this.User = res.data;
                        this.UserAccount = res.data.UserAccount;
                    }
                });
            },
            InsertUpdate() {
                if (this.$route.query.UserId != null) {
                    //update
                    Axios.put("/Api/V1/Update/Update/User", this.User).then(res => {
                        if (res.status == 200) {
                            this.$router.push("/User");
                        }
                    });
                } else {
                    //insert
                    this.User.UserId = 0;
                    Axios.post("/Api/V1/Insert/Insert/User", this.User).then(res => {
                        if (res.status == 200) {
                            this.$router.push("/User");
                        }
                    });
                }
            }
        }
    };
</script>
